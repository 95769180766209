import CountUp from 'react-countup';

import './App.css';

import React, { useRef, useEffect, useState } from "react";

import background from "./background_small_v2.png";

const gugImages = require.context('../../assets/images/new_gugs', true);
const audio = require.context('../../assets/audio', true);

// From https://medium.com/@ruse.marshall/converting-a-vanilla-js-canvas-animation-to-react-78443bad6d7b
const Canvas = (props) => {
    const canvasRef = useRef(null);
    const [context, setContext] = useState(null);

    const resizeCanvas = (context) => {
		const canvas = context.canvas;
		const { width, height } = canvas.getBoundingClientRect();

		if (canvas.width !== width || canvas.height !== height) {
			const { devicePixelRatio: ratio = 1 } = window;
			canvas.width = width * ratio;
			canvas.height = height * ratio;
			context.scale(ratio, ratio);
			return true;
		}
		return false;
	};

    const draw = (imgs) => {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        for (var entityIndex = 0; entityIndex < NUM_ENTITIES; entityIndex++) {
            var img = imgs[entityIndex];
            context.drawImage(img, img.position[0], img.position[1], 100, 100);
        }
    };

    const NUM_ENTITIES = 21;

    var imgs = []
    for (var entityIndex = 0; entityIndex < NUM_ENTITIES; entityIndex++) {
        const imageUrl = gugImages('./entity_' + entityIndex + '.png')
        var img = new Image();
        var direction = [Math.random() - 0.5, Math.random() - 0.5];
        var SPEED = 5;
        var magnitude = Math.sqrt(direction[0] ** 2 + direction[1] ** 2)
        img.velocity = [direction[0] / magnitude * SPEED, direction[1] / magnitude * SPEED]
        img.src = imageUrl;
        img.position = [Math.random() * 1000 + 100, Math.random() * 1000 + 100];
        imgs.push(img)
    }

    useEffect(() => {
        //i.e. value other than null or undefined
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            setContext(ctx);
        }
    }, []);

    useEffect(() => {
        let frameCount = 0;
        let animationFrameId;

        // Check if null context has been replaced on component mount
        if (context) {
            //Our draw came here
            const render = () => {
                resizeCanvas(context);  
                for (var entityIndex = 0; entityIndex < NUM_ENTITIES; entityIndex++) {
                    imgs[entityIndex].position = [(imgs[entityIndex].position[0] + imgs[entityIndex].velocity[0] + context.canvas.width) % context.canvas.width, 
                                                  (imgs[entityIndex].position[1] + imgs[entityIndex].velocity[1] + context.canvas.height) % context.canvas.height]
                }
                
                frameCount++;
                draw(imgs);
                animationFrameId = window.requestAnimationFrame(render);
            };
            render();
        }
        return () => {
            window.cancelAnimationFrame(animationFrameId);
        };
    }, [draw, context]);

    return <canvas ref={canvasRef} {...props} />;
};


function Home() {
  const [interacted, setInteracted] = useState("");

  return (
    <div className="App">
      {
        interacted ? 
        <header className="App-header" style={{backgroundImage: `url(${background})`}}>
          <audio loop autoPlay>
              <source src={ audio('./go_gug_go.mp3') } type="audio/mpeg" />
              Your browser does not support the audio element.
          </audio>
          <div style={{width:"100%", height: "85vh"}}>
            <Canvas style={{height:'100%', width:'100%', margin:'auto'}} />
          </div>
          <div style={{margin: "auto", height: "15vh", display: "flex"}}>
            <div style={{margin: "auto"}}>
              There are probably <CountUp start={0} end={527} style={{color: 'yellow'}}/> GUGs so far.
            </div>
          </div>
        </header>
        : 
        <header className="App-header" style={{backgroundImage: `url(${background})`, height: '100vh'}}>
          <button onClick={() => setInteracted(true)} style = {{width: '50em', height: '20em', fontSize: '1em', borderRadius: '50px'}}> CLICK ME FOR A GUG DANCE PARTY </button>
        </header>
      }
      
    </div>
  );
}

export default Home;