import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {useRef} from 'react';

import { NavLink } from "../components/NavbarElements";

const options = {
    shouldForwardProp: (prop) => prop !== 'hoverShadow',
};

const Item = styled(Paper, options)(({ theme, hoverShadow = 1 }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ':hover': {
        boxShadow: theme.shadows[hoverShadow]
    }
}));

function ItemButton({ text, entity, transferOptions }) {
    return (
      <NavLink
          to={"../converter"}
          state={{'entity': entity, 'transferOptions': transferOptions}}
          style={{float: 'right'}}
        >
        <div style={{backgroundColor: 'gray', color: 'white'}}>
          {text}
        </div>
      </NavLink>
    );
  }



const ItemSelector = ({ images, creatures, transferOptions, entityIndex }) => {
    const buttons = useRef([]);    

    return (
    <div>
        <div style={{'fontSize': 40, 'textAlign': 'center', height: '8vh', backgroundColor: 'teal'}}>
            Your Collection
        </div>
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", height: "82vh", overflow: "hidden", overflowY: "scroll",}} style={{width:'23%', float: 'right'}}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {creatures.map((creature, index) => (
                    <Grid item xs={2} sm={4} md={6} key={index}>
                        <Item hoverShadow={10}
                            onMouseOver={(e) => buttons.current[index].style = 'display: grid'}
                            onMouseOut={(e) => buttons.current[index].style = 'display: none'}>
                            <div style={(entityIndex != index) ? {fontSize: 30} : {fontSize: 30, backgroundColor: 'yellow'}}>
                                {creature.name}
                            </div>
                            <div style={{fontSize: 15}}>
                                {creature.keyword}
                            </div> 
                            <div>
                                <img src={images('./entity_' + index + '.png')} style={{width: "100%"}}/>
                            </div>
                            <div style={{fontSize: 15}}>
                                {creature.effect}
                            </div>
                            <div style={{fontSize: 20}}>
                                Attack: {creature.attack}
                            </div>
                            <div key={index} ref={el => buttons.current[index] = el} style={{display: 'none'}}>
                                <ItemButton text={"Convert to " + transferOptions.target} entity={creature} transferOptions={transferOptions}/>
                            </div>
                        </Item>
                    </Grid>
                ))}
            </Grid>
        </Box>    
    </div>
    )
};

export default ItemSelector;