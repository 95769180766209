import { Canvas, useFrame } from "@react-three/fiber";
import { useLoader, useThree  } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { Suspense, useRef, useState } from "react";
import { Html, useProgress } from '@react-three/drei';

import * as THREE from 'three';
import { TextureLoader } from 'three/src/loaders/TextureLoader'

import './Forge.css';

function Loader() {
  const { progress } = useProgress()
  return (
    <Html center>
        <progress style={{backgroundColor: "rgb(164, 184, 142)", borderRadius: "1", borderColor: "#386256"}} value={progress/100}></progress>
        Loading creator garden
    </Html>
  )
}

const DRACOElement = (props) => {
    // This reference gives us direct access to the THREE.Mesh object
    const ref = useRef()

    const [hovered, hover] = useState(false);

    const controls = useThree(state => state.controls);
    const camera = useThree(state => state.camera);
    
    // TODO: Pack up DRACO into the local files:
    // https://stackoverflow.com/questions/56071764/how-to-use-dracoloader-with-gltfloader-in-reactjs
    const gltf = useLoader(GLTFLoader, props.path, loader => {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/'); // Set your path to the draco files
        loader.setDRACOLoader(dracoLoader);
    });

    useFrame((state, delta) => {
        if (ref.current.totalTime == null) ref.current.totalTime = Math.random() * 100;
        ref.current.totalTime += delta;

        if (ref.current.target == null) {
            ref.current.target = new THREE.Vector3(Math.random() * 15 - 7.5, 0, Math.random() * 15 - 7.5);
        }

        if (ref.current.target.distanceTo(ref.current.position) < 1) {
            ref.current.startAnimationTime = ref.current.totalTime;

            while (ref.current.target.distanceTo(ref.current.position) < 5) {
                ref.current.target = new THREE.Vector3(Math.random() * 15 - 7.5, 0, Math.random() * 15 - 7.5);
            }
        }

        if (ref.current.startAnimationTime != null) {
            const elapsedAnimationTime = ref.current.totalTime - ref.current.startAnimationTime;
            if (elapsedAnimationTime > 3 + Math.random()) {
                ref.current.startAnimationTime = null;
            }

            ref.current.rotation.y += (Math.sin(elapsedAnimationTime) + 1) / 12;
            ref.current.scale.y = 0.6 + (Math.sin(elapsedAnimationTime * 5) + 1) * 0.4;
            ref.current.position.y = (Math.sin(elapsedAnimationTime * 5) + 1) * 0.4 - 0.5;
        } else {
            ref.current.lookAt(ref.current.target);
            const forward = new THREE.Vector3();
            ref.current.getWorldDirection(forward);
            ref.current.position.addScaledVector(forward, 0.02 * (Math.sin(ref.current.totalTime * 5) + 1));

            ref.current.rotateY(-90);
            const cameraForward = new THREE.Vector3();
            ref.current.getWorldDirection(cameraForward);

            ref.current.scale.y = 0.6 + (Math.sin(ref.current.totalTime * 5) + 1) * 0.4;
            ref.current.position.y = (Math.sin(ref.current.totalTime * 5) + 1) * 0.4 - 0.5;
            
            // const angle = Math.atan2(ref.current.position.z, ref.current.position.x);

            // ref.current.position.addScaledVector(new THREE.Vector3(dx, dy, dz), 0.01);
        }

        if (!props.focused || !controls) return;

        controls.target.set(ref.current.position.x, 0.5, ref.current.position.z);
        controls.maxDistance = 1.8;
        controls.minDistance = 1.8;
        controls.maxPolarAngle = Math.PI / 8 * 5;
        camera.fov = 90;
        camera.updateProjectionMatrix();
        controls.update();

        // const angle = Math.PI * 2 * props.index / props.file_names.length;
        // const new_camera_pos = ref.current.position.clone().addScaledVector(cameraForward, -2);
        // new_camera_pos.y = 1;
        
        // const lookAtPosition = ref.current.position.clone();
        // lookAtPosition.y = 0.5;
        
        // state.camera.position.set(...new_camera_pos);
        // state.camera.lookAt(...lookAtPosition);
    });

    return (<primitive 
        ref={ref}
        // onPointerOver={(event) => hover(true)}
        // onPointerOut={(event) => hover(false)}
        position={props.position}
        object={gltf.scene}
        scale={props.scale}
        rotation={props.rotation}
    />);
}

// const GLTFElement = (props) => {
//     const gltf = useLoader(GLTFLoader, props.path);

//     return (<primitive position={props.position} object={gltf.scene} scale={props.scale} />);
// }

const Model = (props) => {

  const positions = [];
  const scales = [];
  const rotations = [];
  var i = 0;
  for (var file_name in props.file_names) {
    var angle = i / props.file_names.length * 2 * Math.PI;
    var x = Math.sin(angle) * 5;
    var z = Math.cos(angle) * 5;
    var dx = Math.random() * 2 - 1;
    var dz = Math.random() * 2 - 1;
    var new_position = [x + dx * 0.1, 0, z + dz * 0.1];
    positions.push(new_position);
    rotations.push([0, angle - Math.PI, 0]);
    i += 1;
    scales.push([1, 0.3 + Math.random(), 1])
  }

  return (
    <>
        {/* {file_names.map((file_name, index) => (
            <GLTFElement path = {"./3d_gugs/" + file_name} position={positions[index]} />
        ))} */}
        {props.file_names.map((file_name, enum_index) => (
            <DRACOElement path = {"./3d_gugs/compressed/" + file_name} position={positions[enum_index]} scale={scales[enum_index]} rotation={rotations[enum_index]} focused={enum_index == props.index}/>
        ))}
    </>
  );
};


// This is the thing we are interested in
// The GreenSquare component renders a mesh.
// Meshes are objects that can have a shape and
// texture.
function GreenSquare() {
    const colorMap = useLoader(TextureLoader, 'Ground_Battle.jpg')
    return (
        // The mesh is at the origin
        // Since it is inside a group, it is at the origin
        // of that group
        // It's rotated by 90 degrees along the X-axis
        // This is because, by default, planes are rendered
        // in the X-Y plane, where Y is the up direction
        <>
        <ambientLight intensity={0.4} />
        <mesh position={[0, -0.75, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={[15, 15, 1]}>
            {/*
                The thing that gives the mesh its shape
                In this case the shape is a flat plane
            */}
            <planeGeometry />
            {/*
                The material gives a mesh its texture or look.
                In this case, it is just a uniform green
            */}
            <meshStandardMaterial map={colorMap} color="#AAB897" toneMapped={false} />
        </mesh>
        <mesh position={[0, -0.76, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={[100, 100, 1]}>
            {/*
                The thing that gives the mesh its shape
                In this case the shape is a flat plane
            */}
            <planeGeometry />
            {/*
                The material gives a mesh its texture or look.
                In this case, it is just a uniform green
            */}
            <meshBasicMaterial color="#AAB897" toneMapped={false} />
        </mesh>
        </>
    );
}

const ContentCreatorWidget = (props) => {
    const file_names = [
        "crazydave11_3D.glb",
        "calebsmythTV_3D.glb",
        "sofa_spudees_3D.glb",
        "lusewing_3D.glb",
        "svm_invictvs_3D.glb",
        "tahrngarth_3D.glb",
        "thesaskozero_3D.glb",
        "thriftyfishing_3D.glb",
        "zoffel_3D_alternative.glb",
        "moon_kestrel_3D.glb"
      ];

    const kguids = [
        "crazydave11",
        "poison bee",
        "couch potato",
        "lusewing",
        "capitalism",
        "minotaur tahrngarth",
        "rainbowspezi",
        "cubepotatooak",
        "rainbow hawke",
        "themoonkestrelbat"
    ];

    const names = [
        "crazydave11",
        "calebsmyth TV",
        "Sofa Spudees",
        "Lusewing",
        "svm_invictvs",
        "Tahrngarth",
        "TheSaskoZero",
        "ThriftyFishin",
        "ChaosZoffel",
        "TheMoonKestrel"
    ]

    const channel_links = [
        "https://www.twitch.tv/crazydave11",
        "https://www.youtube.com/@calebsmythTV",
        "https://www.youtube.com/@SofaSpuddees",
        "https://www.youtube.com/@Lusewing",
        "https://www.twitch.tv/savorysailor",
        "https://www.youtube.com/@tahrngarth8654",
        "https://www.twitch.tv/thesaskozero",
        "https://www.youtube.com/@thriftyfishin",
        "https://www.twitch.tv/chaoszoffel",
        "https://www.twitch.tv/themoonkestrel"
    ]

    const date_first_gugged = [
        "Oct 29th 2024",
        "Oct 14th 2024",
        "Oct 13th 2024",
        "Oct 12th 2024",
        "Oct 11th 2024",
        "Oct 12th 2024",
        "Oct 10th 2024",
        "Oct 9th 2024",
        "Oct 9th 2024",
        "Oct 8th 2024"
    ]

    const [index, setIndex] = useState(Math.floor(Math.random() * file_names.length));

    return (
        <div style={{margin: "0 auto", position: "absolute", top: "5em", width: "25%", color: "#386256", fontWeight: "bold", display: !props.isMobile ? "block" : "none"}}>
            <div style={{width: "100%", margin: "0 auto", backgroundColor: "#A4B88E", border: "2px solid #386256", padding: "6px", float: "left"}}>
                <div style={{margin: "0 auto", textAlign: "center"}}>
                    <div style={{margin: "0 auto", textAlign: "center", fontSize: "1.2em", paddingTop: "10px"}}>
                        Hall of Early Adopters
                    </div>
                    <div style={{margin: "0 auto", textAlign: "center", fontSize: "0.6em"}}>
                        For the content creators that GUGed before it was cool!
                    </div>
                </div>
                <div style={{paddingTop: "20px"}}>
                    <Canvas style={{height: "50vh"}}>
                        <Suspense fallback={<Loader />}>
                        <OrbitControls makeDefault/>
                        <GreenSquare />
                        <Model index={index} file_names={file_names}/>
                        <Environment files="./EncounterAsset_Backgrounds3.jpg" background/>
                        </Suspense>
                    </Canvas>
                </div>
                <div style={{margin: "0 auto", textAlign: "center", paddingTop: "10px", fontSize: "1.2em"}}>
                    {names[index]}
                </div>
                <div style={{margin: "0 auto", textAlign: "center", fontSize: "0.6em"}}>
                    <a href={channel_links[index]} target="_blank">{channel_links[index]}</a>
                </div>
                <div style={{margin: "0 auto", textAlign: "center", fontSize: "0.7em"}}>
                    Became a gug on {date_first_gugged[index]}
                </div>
                <div style={{margin: "0 auto", textAlign: "center", paddingTop: "10px", fontSize: "0.5em"}}>
                    In-game prompt: "{kguids[index]}"
                </div>
                <div style={{margin: "0 auto", textAlign: "center", padding: "10px 0px 10px 0px"}}>
                    <input type="submit" className="submit-button" value="Previous Creator" onClick={() => setIndex((index - 1 + file_names.length) % file_names.length)}/>
                    <input type="submit" className="submit-button" value="Next Creator" onClick={() => setIndex((index + 1) % file_names.length)}/>
                </div>
            </div>
        </div>
        );
}

export default ContentCreatorWidget;