import {useLocation} from 'react-router-dom';

import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { NavLink } from "../components/NavbarElements";

const gugImages = require.context('../../assets/images/gugs', true);
const psImages = require.context('../../assets/images/spaceships', true);
const miscImages = require.context('../../assets/images/misc', true);
const imageMap = {'Cellumancer': gugImages, 'Project Spaceship': psImages}; 
const navMap = {'Cellumancer': '../cellumancer', 'Project Spaceship': '../project-spaceship'}

const Converter = () => {
    const [progress, setProgress] = React.useState(0);
    const [convertingToggle, setConvertingToggle] = React.useState(false);
    let location = useLocation();
    let entityIndex = location.state?.entity.index;
    let sourceImages = imageMap[location.state?.transferOptions.source];
    let targetImages = imageMap[location.state?.transferOptions.target];

    const progressRef = React.useRef(() => {});
    React.useEffect(() => {
      progressRef.current = () => {
        if (progress >= 100) {
          setConvertingToggle(true);
        } else {
          const diff = Math.random() * 30;
          const actualDiff = progress + diff > 100 ? 100 - progress : diff;
          setProgress(progress + actualDiff);
        }
      };
    });

    React.useEffect(() => {
      const timer = setInterval(() => {
        progressRef.current();
      }, 500);
  
      return () => {
        clearInterval(timer);
      };
    }, []);

    return (
    <div className="App">
      <header className="App-header">
        <div style={{backgroundColor: '#333', borderRadius: 40, marginTop: 40}}>
          <div style={{fontSize: 60}}>
            Converter
          </div>
          <div style={{width: '950px'}}>
              <div style={{float: 'left', margin: 5}}>
                Input
                <div style={{width: '300px', height:'300px', backgroundColor: '#aaa', padding: 5}}>
                  <img src={sourceImages('./entity_' + entityIndex + '.png')} style={{width: "100%"}}/>
                </div>
              </div>
              <div style={{width: '300px', height:'300px', float:'left', margin: 5}}>
                Progress
                  <Box sx={{ width: '100%'}} style={{marginTop: '150px'}}>
                    <LinearProgress style={{padding: '10px'}} variant="determinate" value={progress} />
                  </Box>
              </div>
              <div style={{float: 'right', margin: 5}}>
                Output
                <div style={{width: '300px', height:'300px', backgroundColor: '#aaa', padding: 5}}>
                  {
                    convertingToggle ?
                    <NavLink
                        to={navMap[location.state?.transferOptions.target]}
                        state={{'entityIndex': entityIndex}}
                      >
                      <img src={ targetImages('./entity_' + entityIndex + '.png') } style={{width: "100%"}}/>
                    </NavLink>
                    :
                      <img src={ miscImages('./question_mark.png')} style={{width: "100%"}}/>
                  }
                    
                </div>
              </div>
            
          </div>
        </div>
      </header>
    </div>
  );
};

export default Converter;