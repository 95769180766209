import CountUp from 'react-countup';

import './App.css';

import React, { useRef, useEffect, useState } from "react";

import background from "./background_small_v2.png";

const gugImages = require.context('../../assets/images/new_gugs', true);

const ENDPOINT_PATH = "https://web-production-8fbd.up.railway.app/";

// From https://medium.com/@ruse.marshall/converting-a-vanilla-js-canvas-animation-to-react-78443bad6d7b
const Canvas = (props) => {
    const canvasRef = useRef(null);
    const [context, setContext] = useState(null);

    const startIndex = props.startIndex;

    const resizeCanvas = (context) => {
      const canvas = context.canvas;
      const { width, height } = canvas.getBoundingClientRect();

      if (canvas.width !== width || canvas.height !== height) {
          const { devicePixelRatio: ratio = 1 } = window;
          canvas.width = width * ratio;
          canvas.height = height * ratio;
          context.scale(ratio, ratio);
          return true;
      }
      return false;
    };

    const draw = (imgs) => {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        for (var entityIndex = 0; entityIndex < NUM_ENTITIES; entityIndex++) {
            var img = imgs[entityIndex];
            var dx = img.position_percent[0] * context.canvas.width / 100
            var dy = img.position_percent[1] * context.canvas.height / 100
            context.drawImage(img, dx, dy, 100, 100);
        }
    };

    const NUM_ENTITIES = 10;

    var imgs = []
    for (var entityIndex = 0; entityIndex < NUM_ENTITIES; entityIndex++) {
        const imageUrl = gugImages('./entity_' + (startIndex + entityIndex) + '.png')
        var img = new Image();
        img.velocity = [0, -0.5]
        img.src = imageUrl;
        imgs.push(img)
    }

    useEffect(() => {
        //i.e. value other than null or undefined
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            setContext(ctx);
        }
    }, []);

    useEffect(() => {
        let frameCount = 0;
        let animationFrameId;

        // Check if null context has been replaced on component mount
        if (context) {
            for (var entityIndex = 0; entityIndex < NUM_ENTITIES; entityIndex++) {
              imgs[entityIndex].position_percent = [20 + 40 * (entityIndex % 2), Math.floor(entityIndex / 2) / NUM_ENTITIES * 100 * 2];
            }

            //Our draw came here
            const render = () => {
                resizeCanvas(context);
              
                for (var entityIndex = 0; entityIndex < NUM_ENTITIES; entityIndex++) {
                    var x = imgs[entityIndex].position_percent[0];
                    var y = imgs[entityIndex].position_percent[1];
                    var dx = imgs[entityIndex].velocity[0];
                    var dy = imgs[entityIndex].velocity[1];
                    var new_x = x + dx < 0 ? x + dx + 110 : x + dx;
                    var new_y = y + dy < -10 ? y + dy + 110 : y + dy;
                    imgs[entityIndex].position_percent = [new_x, new_y]
                }
                
                frameCount++;
                draw(imgs);
                animationFrameId = window.requestAnimationFrame(render);
            };
            render();
        }
        return () => {
            window.cancelAnimationFrame(animationFrameId);
        };
    }, [draw, context]);

    return <canvas ref={canvasRef} {...props} />;
};

const Register = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  var handleClick = () => {
    console.log(email + " " + password);
    // TODO: Credentials through LEM. Do I need an endpoint for that?
  };

  return (
    <div>
      <div action="/action_page.php" style={{margin: "auto", padding: "30px", backgroundColor: "rgba(100, 100, 100, 0.5)"}}>
        <label for="lname">Email:</label><br/>
        <input type="text" id="lname" name="lname" value={email} onInput={e => setEmail(e.target.value)}/><br/>
        <label for="lname">Password:</label><br/>
        <input type="text" id="lname" name="lname" value={password} onInput={e => setPassword(e.target.value)}/><br/>
        <input type="submit" value="Register" onClick={handleClick}/>
      </div>
    </div>
  );
};


function Forge() {
  return (
    <div className="App">
      <header className="App-header" style={{backgroundImage: `url(${background})`}}>
        <div style={{width:"100%", padding: "0px"}}>
          <div style={{width:"25%", height: "99vh", float: "left"}}>
            <Canvas startIndex={0} style={{height:'100%', width:'100%', margin:'auto'}} />
          </div>
          <div style={{width:"50%", height: "99vh", float: "left", display: "table"}}>
            <div style={{width: "100%", height: "100vh", display: "table-cell", verticalAlign: "middle"}}>
              <Register urls= {[ENDPOINT_PATH + "get_list_of_gugs"]}/>
            </div>
          </div>
          <div style={{width:"25%", height: "99vh", float: "right"}}>
            <Canvas startIndex={10} style={{height:'100%', width:'100%', margin:'auto'}} />
          </div>
        </div>
      </header>
    </div>
  );
}

export default Forge;